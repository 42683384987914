import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import AutoAccidentIcon from "../icons/autoAccidentIcon";
import SlipFallIcon from "../icons/slipFallIcon";
import MedicalMalpracticeIcon from "../icons/medicalMalpracticeIcon";
import WorkplaceInjuryIcon from "../icons/workplaceInjuryIcon";
import ParaquatIcon from "../icons/paraquatIcon";
import ZantacIcon from "../icons/zantacIcon";
import EarplugsIcon from "../icons/earplugsIcon";
import RoundupIcon from "../icons/roundupIcon";

const aboutList = [
    {
        id: 1,
        title: "Auto Accident",
        icon: <AutoAccidentIcon />,
        features: ["Car", "Motorcycle", "Commercial", "Hit and Run"],
    },
    {
        id: 2,
        title: "Slip & Fall",
        icon: <SlipFallIcon />,
        features: [
            "Broken Bones",
            "Sprained Ankles/Wrists",
            "Spine or Nerve Damage",
            "Cuts and Bruises",
        ],
    },
    {
        id: 3,
        title: "Medical Malpractice",
        icon: <MedicalMalpracticeIcon />,
        features: ["Misdiagnosis", "Negligence", "Child Birth", "Nursing Home"],
    },
    {
        id: 4,
        title: "Workplace Injury",
        icon: <WorkplaceInjuryIcon />,
        features: [
            "Injured at Work",
            "Disability Insurance",
            "ERSA Disability",
            "Denied Compensation",
        ],
    },
    {
        id: 5,
        title: "Paraquat",
        icon: <ParaquatIcon />,
        features: ["Lung Damage", "Kidney Damage", "Esophagus", "Parkinson"],
    },
    {
        id: 6,
        title: "Zantac",
        icon: <ZantacIcon />,
        features: [
            "Bladder Cancer",
            "Breast Cancer",
            "Stomach Cancer",
            "Kidney Cancer",
        ],
    },
    {
        id: 7,
        title: "3M Earplugs",
        icon: <EarplugsIcon />,
        features: ["hearing loss", "tinnitus", "hearing damage", "deafness"],
    },
    {
        id: 8,
        title: "Roundup",
        icon: <RoundupIcon />,
        features: [
            "Cancer",
            "Liver Damage",
            "kidney Damage",
            "Risk for Pregnant Women",
        ],
    },
];

export default function About(): ReactElement {
    return (
        <section className={`mt-10 mb-10 lg:mt-16`}>
            <div className="max-w-7xl mx-auto block px-4vw lg:px-4">
                <h2
                    style={{ color: "black" }}
                    className={`text-center text-xl lg:text-4xl mb-14`}
                >
                    About Our Services
                </h2>
                <div
                    className={`flex flex-wrap justify-between ${styles["flexItems"]}`}
                >
                    {aboutList.map((item) => {
                        return (
                            <div className={`${styles["item"]}`} key={item.id}>
                                <div
                                    className={`${styles["num"]} text-3xl bold`}
                                >
                                    {item.id}
                                </div>
                                <div
                                    className={`${styles["itemIcon"]} text-center`}
                                >
                                    {item.icon}
                                </div>
                                <div
                                    className={`${styles["itemTitle"]} text-xl text-center`}
                                >
                                    {item.title}
                                </div>
                                <ul>
                                    {item.features.map((feature) => (
                                        <li
                                            key={feature}
                                            className={`${styles["feature"]} text-lg`}
                                        >
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    );
}
