import React, { ReactElement } from "react";

export default function SlipFallIcon(): ReactElement {
    return (
        <svg
            width="118"
            height="80"
            viewBox="0 0 118 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M88.8648 80H16.7151C16.4436 79.9993 16.1834 79.891 15.9914 79.6987C15.7994 79.5065 15.6913 79.2459 15.6906 78.9741V77.1482C15.6913 76.8763 15.7994 76.6157 15.9914 76.4235C16.1834 76.2312 16.4436 76.1229 16.7151 76.1222H88.8648C89.1363 76.1229 89.3965 76.2312 89.5884 76.4235C89.7804 76.6157 89.8886 76.8763 89.8893 77.1482V78.9741C89.8886 79.2459 89.7804 79.5065 89.5884 79.6987C89.3965 79.891 89.1363 79.9993 88.8648 80Z"
                fill="#EA7969"
            />
            <path
                d="M94.9865 68.3522C95.8673 68.2058 96.8384 67.5056 97.144 66.7966L97.1678 65.6103C96.8833 65.0034 95.7925 64.8117 94.8842 65.1262L73.8877 71.569C72.9787 71.8839 72.9573 72.0234 73.8384 71.8754L94.9865 68.3505V68.3522ZM98.2657 53.2691C99.1917 52.8068 99.8559 51.917 99.743 51.2924L98.9874 50.4458C98.2599 50.1316 96.8666 50.4386 96.0212 51.032L75.916 64.1207C75.0703 64.713 75.1359 64.8209 76.0611 64.359L98.2657 53.2691V53.2691ZM74.5335 68.2698C73.351 68.6589 73.3255 68.4959 74.4768 67.909L101.452 55.3282C102.604 54.7416 104.222 54.6814 104.87 55.2968L105.325 56.6407C105.184 57.5183 104.101 58.5538 102.919 58.9425L74.5335 68.2695V68.2698Z"
                fill="#EA7969"
            />
            <path
                d="M13.0198 34.6989L14.764 48.0685C15.1263 49.5744 16.0387 50.6038 17.4548 50.9122C18.0292 51.037 18.6244 51.0296 19.1955 50.8904C21.2904 50.3849 22.2405 48.4919 21.7351 46.394L20.4277 35.7633L28.4352 31.3722L38.9136 51.1089C38.9136 51.1089 39.8242 52.1202 40.394 52.5212L64.7424 69.6593C65.663 70.3071 66.8028 70.5621 67.9111 70.3683C69.0194 70.1745 70.0054 69.5477 70.6523 68.6259C71.2991 67.704 71.5538 66.5626 71.3602 65.4528C71.1667 64.3429 70.5408 63.3555 69.6203 62.7077L50.1972 49.0362L63.1176 42.8964L79.2526 49.5785C79.7683 49.7974 80.3222 49.9119 80.8823 49.9154C81.4424 49.9188 81.9977 49.8111 82.516 49.5984C83.0343 49.3858 83.5055 49.0724 83.9022 48.6765C84.2989 48.2805 84.6134 47.8097 84.8275 47.2914C85.0415 46.773 85.1509 46.2173 85.1494 45.6564C85.1478 45.0955 85.0353 44.5404 84.8184 44.0233C84.6014 43.5061 84.2843 43.0371 83.8854 42.6434C83.4864 42.2496 83.0136 41.9389 82.4941 41.7292L64.62 34.3255C64.0724 34.099 63.4834 33.9898 62.8911 34.005C62.2988 34.0201 61.7162 34.1592 61.1807 34.4133L52.1933 38.6843C50.8774 39.1387 49.7353 38.5114 48.7754 37.4391C45.4934 33.7713 40.9388 25.5115 43.1031 24.4723L50.6928 20.9025C51.1606 20.6821 51.5801 20.3709 51.9268 19.987C52.2735 19.603 52.5406 19.1539 52.7126 18.6657L57.478 5.14332C57.8222 4.16604 57.7647 3.09187 57.318 2.15706C56.8714 1.22225 56.0723 0.503364 55.0965 0.158503C53.0628 -0.562124 51.3394 1.29306 50.6225 3.32797L46.2334 14.3079L15.2232 30.5399C13.6049 31.3613 12.8662 33.1565 13.0198 34.6992V34.6989ZM24.8939 19.8438C21.0726 17.9073 19.5429 13.2346 21.4774 9.40819C23.4119 5.58176 28.0769 4.04928 31.8983 5.98649C35.7197 7.92371 37.2494 12.5964 35.3155 16.4221C33.3817 20.2479 28.7143 21.7804 24.8939 19.8438Z"
                fill="#EA7969"
            />
        </svg>
    );
}
