import React, { ReactElement } from "react";

export default function MedicalMalpracticeIcon(): ReactElement {
    return (
        <svg
            width="118"
            height="80"
            viewBox="0 0 118 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M56.2067 46.1829L71.5178 34.7588L61.8523 33.2075L71.3219 24.8342L68.2216 0L37.5746 3.77854L40.3465 25.9882L18 28.743L21.8012 59.2021L44.1484 56.4466L45.6031 68.098L66.9059 46.5128L56.2067 46.1829Z"
                fill="#EA7969"
            />
            <path
                d="M85.308 27.2654L76.991 33.6132L84.4182 34.6214L66.2498 43.6865L75.666 44.934L45.6031 68.098L45.1593 78.7284L76.0151 80L76.9483 57.6396L99.4472 58.567L100.727 27.9012L85.308 27.2654Z"
                fill="#EA7969"
            />
        </svg>
    );
}
